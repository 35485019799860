@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter';
}
.container {
    min-width: 1200px;
}
.header-buttons {
    display: flex;
    align-items: center;
}
.table-data-futures { 
    margin-top: 30px;
}
.table-data-futures-title {
    margin-bottom: 25px;
}